import { Button, ButtonProps } from "../Button";
import { BrokerageWebPath } from "../../utils/paths";
import { FC } from "react";
import { useSearchParams } from "next/navigation";

type GetStartedButtonProps = ButtonProps & {
  withCaret?: boolean;
};
const GetStartedButton: FC<GetStartedButtonProps> = ({
  className,
  withCaret,
  ...props
}) => {
  const params = useSearchParams();

  const onClick = () => {
    location.href = `${BrokerageWebPath.signup}?${params.toString()}`;
  };

  return (
    <Button className={className} onClick={onClick} {...props}>
      Get started {withCaret && "->"}
    </Button>
  );
};

export default GetStartedButton;
